import {extendedTargetAudienceOptions} from 'options/extendedTargetAudienceOptions'
import {targetAudienceOptions} from 'options/targetAudienceOptions'

export const nl = {
	project_name: 'Atletiek Vlaanderen',
	header: {
		hamburger: {
			arialabel: 'Open mobiel menu'
		},
		search: {
			url: '/zoeken',
			title: 'Zoek'
		}
	},
	hero: {
		allianz: {
			url: 'https://allianz.be/nl/prive.html',
			aria_label: 'Ga naar de Allianz website'
		},
		val: {
			url: '/',
			aria_label: 'Ga naar de startpagina van Atletiek Vlaanderen'
		}
	},
	mixedsection: {
		changes: "Gewijzigde pagina's"
	},
	athletes: {
		disciplines: 'Discipline(s):',
		birthday: 'Geboortedatum:',
		club: 'Club:',
		trainer: 'Trainer:'
	},
	calendar: {
		loadpast: 'Laad vorige items'
	},
	calendar_item: {
		labelvalues: {
			starttime: 'Tijdstip',
			organisation: 'Organisatie:',
			participation: 'Deelname:',
			target_audience: 'Doelgroep:',
			docent: 'Docent:',
			location: 'Locatie:',
			registration: 'Inschrijven:',
			class_schedule: 'Lessenrooster:',
			extra: 'Extra info:'
		},
		target_audience: {
			alltrainers: 'Alle trainers',
			...targetAudienceOptions,
			...extendedTargetAudienceOptions
		},
		notFound: 'Er werden geen kalender items gevonden.'
	},
	club: {
		events: 'Evenementen',
		contact: {
			title: 'Contact',
			email: 'E-mailadres: ',
			website: 'Website: '
		},
		secretary: {
			title: 'Secretaris',
			name: 'Naam: '
		},
		facilities: {
			title: 'Terreinen',
			type: 'Type: ',
			report: 'Keuringsverslag:',
			dressing_room: 'douches',
			washing_facility: 'kleedkamers'
		}
	},
	clubs: {
		news: '/club/clubnieuws',
		readmore: 'Lees al het clubnieuws'
	},
	educations: {
		filters: {
			target_audience: 'Doelgroep:',
			province: 'Plaats:',
			type: 'Type:'
		},
		notFound: 'Er werden geen opleidingen gevonden.'
	},
	refresher_courses: {
		filters: {
			target_audience: 'Doelgroep:',
			province: 'Plaats:',
			type: 'Type:'
		},
		notFound: 'Er werden geen bijscholingen gevonden.'
	},
	error404: {
		title: 'Pagina niet gevonden',
		text: 'De pagina die je probeert te bereiken is niet beschikbaar of is onlangs verwijderd.',
		btn: 'Ga naar de startpagina'
	},
	faq: {
		default: 'Algemeen'
	},
	news: {
		url: '/nieuws',
		title: 'Nieuws',
		readmore: 'Lees al het nieuws',
		withtag: 'Nieuws met tag'
	},
	news_detail: {
		tags: 'Tags:',
		author: 'Auteur: ',
		publication_date: 'Publicatiedatum: ',
		location: 'Locatie: '
	},
	persons: {
		jobfunction: 'Functie:',
		email: 'Email:',
		phone: 'Telefoon:'
	},
	rankings: {
		reset: 'Nieuwe opzoeking',
		submit: 'Zoeken',
		select: 'Selecteer',
		no_results: 'Geen resultaten',
		ranking: 'Ranglijst',
		result: {
			number: 'Nr',
			performance: 'Prestatie',
			wind: 'Wind',
			name: 'Naam',
			club: 'Club',
			contest: 'Wedstrijd',
			city: 'Plaats',
			date: 'Datum prestatie'
		}
	},
	search: {
		channels: {
			rankings: 'Ranking',
			page: 'Pagina',
			overview: 'Overzicht',
			az_list: 'Lijst',
			list: 'Lijst',
			main_category: 'Categorie',
			news_item: 'Nieuwsbericht',
			club: 'Club',
			event: 'Evenement',
			news_overview: 'Nieuws overzicht',
			athletes: 'Atleten',
			athlete: 'Atleet',
			persons: 'Personen',
			person: 'Persoon',
			calendar: 'Kalender',
			calendar_item: 'Kalender item',
			facility: 'Club'
		}
	},
	fetcher: {
		search: {
			placeholder: 'Zoeken op atletiek.be',
			empty: 'Geef hierboven een zoekterm in.',
			empty_result: 'Geen resultaten gevonden voor zoekterm "::searchTerm::".'
		},
		documents: {
			placeholder: 'Zoeken op documenten',
			empty: 'Geef hierboven een zoekterm in.',
			empty_result: 'Geen documenten gevonden met zoekterm "::searchTerm::".'
		}
	},
	cookiesmissing: {
		text: (category) =>
			`Accepteer de ${category}-cookies om de inhoud weer te geven.`,
		button: 'Verander uw toestemming'
	}
}
